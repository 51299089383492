.lang-menu-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  margin: 0;
  z-index: 100;

  .lang-btn {
    float: left;
    width: 80px;
    height: 80px;
    color: #999999;
    background: #f5f5f5;
    cursor: pointer;
    font-size: 17px;
    text-align: center;
    vertical-align: central;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: AlmoniTzar;
  }
  .lang-btn.active {
    color: #fff;
    background: #fc5726;
  }
}
