.input-field {
  font-family: Almoni;
  label {
    display: block;
    font-weight: bold;
    font-size: 18px;
    color: #555;
  }

  input {
    width: 80vw;
    max-width: 400px;
    font-size: 20px;
    padding: 8px;
    border-radius: 5px;
    margin: 3px;
    caret-color: #fc5726;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  input:focus {
    border: 1px solid #fc262f;
  }
  input::placeholder {
    color: #b3adad;
    font-size: 20px;
    text-align: center;
  }
}
