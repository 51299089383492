.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  .loader-o {
    height: 80px;
    width: 45px;
    border: black 15px solid;
    border-radius: 15px;
    margin: auto;
    animation: loader 1.5s linear infinite alternate-reverse;
  }

  @keyframes loader {
    from {
      width: 45px;
    }
    to {
      width: 195px;
    }
  }
}
