.validation-message{

    .validation-message-wrapper{
        display: inline-block;
        position: relative;

        .error-text{
            display: inline-block;
            padding: 0 4px 18px;
            color:red;
            font-weight: bold;
        }

        .fa.fa-check-circle, .fa.fa-times-circle{
            font-size: 22px;
            padding: 6px 0 0 0;
        }

        .fa.fa-check-circle{
            color: green;
        }

        .fa.fa-times-circle{
            color: red;
        }
    }
   
    
}