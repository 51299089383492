.app-wrapper {
  margin: 0;
  min-height: 100vh;
  direction: rtl;
  color: #383838;
  background-color: #eee;
  font-size: 16px;

  .header-wrapper {
    margin: 0;
    position: fixed;
    padding: 20px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: #eee;
    z-index: 3;

    .logo-wrapper {
      display: inline-block;
      position: fixed;
      top: 0;
      left: 0;

      img {
        height: 100px;
        padding: 0;
        border-top: 0;
        border-bottom: 1px;
      }
    }

    .welcome {
      display: inline-block;
      position: fixed;
      right: 6em;
      top: 1em;
      direction: rtl;
    }
    .station {
      display: inline-block;
      position: fixed;
      left: 11em;
      //color: #3498db;
      direction: rtl;
    }
  }

  .content-wrapper {
    height: calc(100% - 40px);
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .buttons-wrapper {
      display: block;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      button {
        display: inline;
      }
    }
  }
}
