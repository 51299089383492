.delete-account-screen {
  .input-wrapper {
    display: flex;
    justify-content: center;
  }
  .info {
    width: 400px;
    text-align: center;
    padding: 20px 0;
  }
}
